import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Select from 'react-select';

const FormGroup = ({
	id,
	name,
	formKey,
	htmlForKey,
	label,
	value,
	onChange,
	type = 'text',
	options = [],
	disabled = false,
	placeholder = '',
	requiredFields = {},
	...additionalProps
}) => {
	const isRequired = requiredFields[formKey];

	let inputElement;
	if (type === 'select') {
		inputElement = (
			<Select
				id={id}
				name={name}
				className="react-select-container"
				classNamePrefix="react-select"
				value={value}
				options={options}
				onChange={onChange}
				isDisabled={disabled}
				{...additionalProps}
			/>
		);
	} else if (type === 'number') {
		inputElement = (
			<NumberFormat
				id={id}
				value={value}
				onValueChange={onChange}
				className="input input--med"
				placeholder={placeholder}
				disabled={disabled}
				name={name}
				{...additionalProps}
			/>
		);
	} else if (type === 'checkbox') {
		inputElement = (
			<input
				type="checkbox"
				id={id}
				name={name}
				className="input--check"
				checked={value}
				onChange={onChange}
				disabled={disabled}
				{...additionalProps}
			/>
		);
	} else if (type === 'radio') {
		inputElement = (
			<input
				type="radio"
				id={id}
				name={name}
				className="input--radio"
				checked={value}
				onChange={onChange}
				disabled={disabled}
				{...additionalProps}
			/>
		);
	} else {
		inputElement = (
			<input
				type={type}
				id={id}
				name={name}
				className="input input--med"
				value={value}
				placeholder={placeholder}
				onChange={onChange}
				disabled={disabled}
				{...additionalProps}
			/>
		);
	}

	return (
		<div className="form__group">
			<div className="form__group__header">
				<label htmlFor={htmlForKey || id} className="form__group__label">
					{label}
				</label>
				{isRequired && (
					<span data-tooltip="Required" className="form__group__required">
						*
					</span>
				)}
			</div>
			{inputElement}
		</div>
	);
};

FormGroup.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	formKey: PropTypes.string.isRequired,
	htmlForKey: PropTypes.string,
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
	onChange: PropTypes.func.isRequired,
	type: PropTypes.string,
	options: PropTypes.array,
	disabled: PropTypes.bool,
	placeholder: PropTypes.string,
	requiredFields: PropTypes.object,
};

export default FormGroup;
